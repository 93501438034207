import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { gear, diploma, presentation_2 } from '../../images/icons';
import { teaching_team } from '../../images/team';


export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>AP Enrichment / SAT II</div>
          <div className={styles.description}>Our tutoring and enrichment classes for AP exams and SAT II exams provide students with the skills and knowledge they need to ace these critical exams.</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={gear} />
          <div className={styles.meta}>Comprehensive Selection</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>Whether you are preparing for a STEM subject like Biology or Calculus, or a Humanities subject like the AP Language & Composition Exam, we have preparation options for you. Our AP and SAT II afford an unparalleled selection of materials and subject-matter expertise.</div>
        </div>
        <div className={styles.card}>
          <img src={diploma} />
          <div className={styles.meta}>Get Ahead at College</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>Students who take our AP and SAT II classes regularly achieve a 4 or 5 on their AP Exam, and top percentile scores on their SAT IIs. With these results, students receive advanced placement or advanced credit at many colleges around the world. Showing up on the first day of university with credits already awarded puts students ahead of the crowd.</div>
        </div>
        <div className={styles.card}>
          <img src={presentation_2} />
          <div className={styles.meta}>Instructors with Expertise</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Every instructor on our team is dedicated to instilling a passion for reading and learning. Our instructors, graduates from world-class universities, promote and environment that is fun and engaging while also a place for serious study and self-development. <Link to='/team/main'>Meet our team of amazing instructors here.</Link></div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Interested in signing up for this course?' cta='Register Today'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Lesson Offerings</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>STEM Offerings</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Biology - AP, IB, SAT II</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Chemistry - AP, IB</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Physics - AP, IB</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>AP Calculus B/C; SAT II Math Level 2</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>Humanities Offerings</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>AP World History</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>AP US History</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>AP Language and Composition; AP Literature and Composition</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>AP Psychology</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>1-on-1 Tutoring</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Flexible scheduling</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Individualized curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Online or offline lessons</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Analytics-based instruction</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)